<template>
  <div style="margin-top: 50px; margin-bottom: 20px">
    <bigDisplay :bigDisplayContent="bigDisplayContent"> </bigDisplay>
    <div>
      <el-row type="flex" justify="space-around">
        <el-col :span="7">
          
          <cardVue :paperText="paperText2" />
          <cardVue :paperText="paperText4" />
          <cardVueEN :paperText="paperText10" />
          <cardVue :paperText="paperText7" />
          <cardVue :paperText="paperText11" />
          <cardVue :paperText="paperText15" />
          <cardVue :paperText="paperText18" />
          <cardVue :paperText="paperText21" />
          <cardVue :paperText="paperText24" />
          <cardVue :paperText="paperText27" />
          <cardVue :paperText="paperText29" />
          <cardVue :paperText="paperText32" />
          <cardVue :paperText="paperText35" />
          <cardVue :paperText="paperText38" />
          <cardVue :paperText="paperText41" />
          <cardVue :paperText="paperText45" />
        </el-col>
        <el-col :span="7">
          <cardVue :paperText="paperText12" />
          <cardVue :paperText="paperText1" />
          <cardVue :paperText="paperText5" />
          <cardVue :paperText="paperText8" />
          <cardVue :paperText="paperText16" />
          <cardVue :paperText="paperText17" />
          <cardVue :paperText="paperText19" />
          <cardVue :paperText="paperText22" />
          <cardVue :paperText="paperText26" />
          <cardVue :paperText="paperText28" />
          <cardVue :paperText="paperText34" />
          <cardVue :paperText="paperText37" />
          <cardVue :paperText="paperText39" />
          <cardVue :paperText="paperText44" />
          <cardVue :paperText="paperText46" />
          <cardVue :paperText="paperText47" />
          
        </el-col>
        <el-col :span="7">
          <cardVue :paperText="paperText3" />
          <cardVue :paperText="paperText6" />
          <cardVue :paperText="paperText9" />
          <cardVue :paperText="paperText13" />
          <cardVue :paperText="paperText14" />
          <cardVue :paperText="paperText20" />
          <cardVue :paperText="paperText23" />
          <cardVue :paperText="paperText25" />
          <cardVue :paperText="paperText30" />
          <cardVue :paperText="paperText31" />
          <cardVue :paperText="paperText36" />
          <cardVue :paperText="paperText40" />
          <cardVue :paperText="paperText42" />
          <cardVue :paperText="paperText43" />
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>

import bigDisplay from "@/views/bigDisplay";
import cardVue from "@/views/myDaily/cardVue";
import cardVueEN from "@/views/myDaily/cardVueEN";
export default {
  name: "myDaily",
  data() {
    return {
      // 大图展示数据
      bigDisplayContent: {
        topImgUrl: require("@/assets/imgs/河边露营.png"), //传递图像要使用require，直接传递字符串 行不通
        leftImgUrl: require("@/assets/imgs/杨蝶拍摄.jpg"),
        midImgUrl: require("@/assets/imgs/年轮.jpg"),
        rightImgUrl: require("@/assets/imgs/五所.jpg"),
        oneText: "为什么想着写随笔？",
        twoText: "Why pen a diary's prose?",
        threeText: "写随笔是自己的个人喜好，喜欢记录自己的生活",
        fourText:
          "以前没怎么注意，现在才注意到随笔的美好。这里摘抄一句泰戈尔的诗句：",
        fiveText:
          "तितली महीनों की गिनती नहीं करती, बल्कि क्षणों की गिनती करती है, और उसके पास पर्याप्त समय होता है।",
        sixText: "蝴蝶不计算月份，只计算瞬间，而时间已足够",
        sevenText:
          "The butterfly counts not months but moments, and has time enough",
        leftType: "CAMERA",
        leftHead: "福建的海边",
        leftAuthor: "by  Butterfly",
        midType: "CAMERA",
        midHead: "艺术品：七十",
        midAuthor: "by 广州美术馆",
        rightType: "CAMERA",
        rightHead: "夕阳也下班",
        rightAuthor: "by Dawn",
        color: "#04333e",
        web: "https://www.gdmoa.org/",
      },
      paperText1: {
        title: "鲜花",
        ptitle: "Flowers 💐",
        imageUrl: require("@/views/myDaily/images/flower.jpg"),
        text: "人们总以为没有走过的路上开满鲜花",
        time: "2024-03-20",
      },
      paperText2: {
        title: "飞船",
        ptitle: "Spaceship 🚀",
        imageUrl: require("@/views/myDaily/images/飞船.png"),
        text: "她是浩瀚宇宙中被遗弃的飞船，沉寂多年的对讲机里，他是唯一的应答",
        time: "2024-03-30",
      },
      paperText3: {
        title: "科学",
        ptitle: "Science 🪐",
        imageUrl: require("@/views/myDaily/images/science.webp"),
        text: "在这里，在科学的前沿，在人类认知能够抵达的边界 科学变得愈发迷人，它闪耀在对原初想法的锻造中，在自觉和尝试里，在那些被选择又被放弃的道路上，在不断产生的热情中，在于努力想象那些从未被想像过的事",
        time: "2023-07-17",
      },
      paperText4: {
        title: "三体",
        ptitle: "Sun ☀️☀️☀️",
        imageUrl: require("@/views/myDaily/images/三体.webp"),
        text: "两堆虫子四百年的斗争，在虫子眼里很伟大，很震撼，可当一千万年过去后，剩下的只有两颗流星在空中划过的尾迹",
        time: "2024-03-31",
      },
      paperText5: {
        title: "狗狗",
        ptitle: "Dog 🐕",
        imageUrl: require("@/views/myDaily/images/狗狗.jpg"),
        text: "大概是太想你了，看到路边的狗，都觉得是你",
        time: "2024-03-23",
      },
      paperText6: {
        title: "清醒的沉溺",
        ptitle: "F**k 😶 ",
        imageUrl: require("@/views/myDaily/images/清醒的沉溺.jpg"),
        text: "是辗转难眠的夜，是沉溺的想想与清醒的现实尽情缠绵，是开了又关，关了又开的聊天界面，是回不去的昨天，是没有你的明天，是无尽深渊，是无尽恐惧",
        time: "2024-03-11",
      },
      paperText7: {
        title: "缠绵",
        ptitle: "Romantic 🕣",
        imageUrl: require("@/views/myDaily/images/缠绵.jpg"),
        text: "互相缠绵吧，在这温柔的光阴里，什么都不重要",
        time: "2024-02-21",
      },
      paperText8: {
        title: "浮生六记",
        ptitle: "沈复 📙",
        imageUrl: require("@/views/myDaily/images/浮生六记.webp"),
        text: "那些细小的花朵，散发着永远的芬芳",
        time: "2024-01-27",
      },
      paperText9: {
        title: "情书",
        ptitle: "Love letter💌",
        imageUrl: require("@/views/myDaily/images/女子.jpg"),
        text: "这世间的真话本就不多，一个女子的脸红，胜过一大段对白。可后来有了胭脂，便分不清是真情还是假意",
        time: "2024-01-27",
      },
      paperText10: {
        title: "Blade Runner",
        ptitle: "Roy 😭",
        imageUrl: require("@/views/myDaily/images/银翼杀手.gif"),
        text1: "I've seen things you people wouldn't believe.",
        text2: "Attack ships on fire of the shoulder of Orion.I watched C-beams glitter near in the dark near the Tannhauser Gate.",
        text3: "All those moments will be lost in time like tears in rain.Time to die",
        time: "2024-04-6",
      },
      paperText11: {
        title: "情侣",
        ptitle: "Lover ❤️",
        imageUrl: require("@/views/myDaily/images/情侣.jpg"),
        text:"你看那对小情侣，他们口袋里面可能一百块都没有，但却比任何人富有",
        time: "2024-03-13",
      },
      paperText12: {
        title: "救赎",
        ptitle: "Redemption 🔥",
        imageUrl: require("@/views/myDaily/images/救赎.jpg"),
        text:"口渴的时候，喝什么都爽口，身在深渊里，看谁都像救赎",
        time: "2024-01-13",
      },
      paperText13: {
        title: "白月光",
        ptitle: "Longing ❤️‍🔥",
        imageUrl: require("@/views/myDaily/images/白月光.jpg"),
        text:"根本就没有什么白月光，那不就是年幼时的见色起意加上一点骨子里的自备，给她套上一层神性光辉，在爱而不得的沉淀下，她成了白月光，你成了光的奴隶",
        time: "2024-04-12",
      },
      paperText14: {
        title: "向阳",
        ptitle: "The SUN 🌿☀️🌿 ",
        imageUrl: require("@/views/myDaily/images/稻草人.jpg"),
        text:"我心里有一簇迎着烈日而生的花，比一切美酒都要芬芳，滚烫的馨香淹没过稻草人的胸膛，草扎的精神，从此万寿无疆",
        time: "2024-04-14",
      },
      paperText15: {
        title: "胆怯",
        ptitle: "Timidity ✋",
        imageUrl: require("@/views/myDaily/images/胆怯.png"),
        text:"神明没收了人类的胆怯 这使少年的青春轰轰烈烈",
        time: "2024-04-23",
      },
      paperText16: {
        title: "命运",
        ptitle: "Dawn ❤️",
        imageUrl: require("@/views/myDaily/images/命运.png"),
        text:"我不停的反问自己，命运之手把我交付与那特殊的时刻，我是否有我想象中那么勇敢",
        time: "2024-04-27",
      },
      paperText17: {
        title: "勇气",
        ptitle: "courage ❤️‍🔥",
        imageUrl: require("@/views/myDaily/images/勇气.png"),
        text:"勇气，就是一开始就知道自己会输，但仍然去做，并且无论如何坚持到底",
        time: "2024-04-27",
      },
      paperText18: {
        title: "漫展",
        ptitle: "Marvel Exhibition 🧑‍🎨",
        imageUrl: require("@/views/myDaily/images/阿尼亚.jpg"),
        text:"看着漫展上蓬勃的生命力，想着我当年其实也是这样 哈哈",
        time: "2024-05-05",
      },
      paperText19: {
        title: "爱情的心酸",
        ptitle: "みやざき はやお 宫崎骏 🧑",
        imageUrl: require("@/views/myDaily/images/龙猫.webp"),
        text:"其实我很早就知道我们不合适，但我还是拒绝了所有人，陪你走过一段没有结果的路。虽然时间不长，但毕生难忘，想想真是心酸啊，留下你和放下你，我都做不到",
        time: "2024-05-13",
      },
      paperText20: {
        title: "奇怪的人",
        ptitle: "村上春树 🧑",
        imageUrl: require("@/views/myDaily/images/奇怪的人.jpg"),
        text:"看海看久了想见人，见人见多了想看海，真是怪事",
        time: "2024-05-18",
      },
      paperText21: {
        title: "家乡 ",
        ptitle: "bilibili 留言 🏞️",
        imageUrl: require("@/views/myDaily/images/家乡.webp"),
        text:"我的老家很神奇，他只在我小时候有一年四季，我长大了，他就只有冬天了",
        time: "2024-05-26",
      },
      paperText22: {
        title: "失眠 ",
        ptitle: "Dawn 🌙",
        imageUrl: require("@/views/myDaily/images/失眠.jpg"),
        text:"她好像知道我特别困，所以每晚都入我脑海，轻浮地挑拨神经，释放着独属于她的多巴胺，一遍又一遍",
        time: "2024-05-29",
      },
      paperText23: {
        title: "风筝",
        ptitle: "追风筝的人 🪁",
        imageUrl: require("@/views/myDaily/images/风筝.jpg"),
        text:"许多年过去了，人们说陈年旧事可以被埋葬，然而我终于明白这是错的，因为往事会自行爬上来",
        time: "2024-06-02",
      },
      paperText24: {
        title: "事",
        ptitle: "史铁生 📘",
        imageUrl: require("@/views/myDaily/images/事.jpg"),
        text:"我什么都没有忘，只是有些事只适合收藏，不能说，不能想，却也不能忘",
        time: "2024-06-03",
      },
      paperText25: {
        title: "青云山",
        ptitle: "汕尾-青云山 🏞️",
        imageUrl: "https://www.1ys53p.top/1718592495796.jpg",
        text:"答案在哪？青云山上的青瓦琉璃之间，皆是答案",
        time: "2024-06-10",
      },
      paperText26: {
        title: "无边",
        ptitle: "无边的蓝 ⏳︎",
        imageUrl: "https://www.1ys53p.top/1718592348858.jpg",
        text:"为什么山里的孩子对大海总会有一种难以言语的“向往”",
        time: "2024-06-13",
      },
      paperText27: {
        title: "拥有你",
        ptitle: "bilibili-狂想青年久居",
        imageUrl: "https://www.1ys53p.top/1718592287820.jpg",
        text:"我以为，我读完你，就能拥有你。毕竟，你的蜿蜒曲折和高山丛林，身体里的烈火与潮湿，我一一作了注释和解析。只不过。我高估了自己，我是读者，只是其中之一 ",
        time: "2024-06-16",
      },
      paperText28: {
        title: "Dawn 生日快乐",
        ptitle: "Dawn-🎂 ",
        imageUrl: "https://www.1ys53p.top/1719747239517.jpg",
        text:"希望自己可以早点找到“答案”",
        time: "2024-07-01",
      },
      paperText29: {
        title: "拥有我",
        ptitle: "bilibili-狂想青年久居 ",
        imageUrl: "https://www.1ys53p.top/1719747689911.jpg",
        text:"我以为，你读完我，就能读懂我。毕竟，你的解析注释与抚摸，眼睛里的心疼和浴火，我一一回应，又在纸上添了些笔墨。只是，你低估了我，你只是读者，不是我",
        time: "2024-06-30",
      },
      paperText30: {
        title: "楼梯上的女人",
        ptitle: "楼梯上的女人-本哈德·施林克",
        imageUrl: "https://www.1ys53p.top/1720504588616.jpg",
        text:"在所有摊在我面前有待去做的事情里，我都是可以被替代的，唯有那些被我抛在身后已经做过的事情里，我是无可替代的。",
        time: "2024-07-09",
      },
      paperText31: {
        title: "小小的失败",
        ptitle: "楼梯上的女人-本哈德·施林克",
        imageUrl: " ",
        text:"正是这些小小的失败作祟，才总是让我们无法释怀?新车的第一道小擦痕比日后的大剐痕更令人心痛。那些小刺比大刺更难剔除，有的时候用针挑都没用，我们只有等待它们化脓流出。早年跌的大跟头让我们的人生转向一个新的方向。早年摔的小跤不会改变我们，而是会伴随着我们折磨我们，成为始终去不掉的肉中刺，于是补救的机会变得很诱人，它看似唾手可得，但终究只是假象🙌",
        time: "2024-07-13",
      },
      paperText32: {
        title: "生存",
        ptitle: "刘慈欣☀️☀️☀️",
        imageUrl: "https://www.1ys53p.top/1722331487048.webp",
        text:"弱小和无知不是生存的障碍，傲慢才是",
        time: "2024-07-30",
      },
      paperText33: {
        title: "生存",
        ptitle: "刘慈欣☀️☀️☀️",
        imageUrl: "https://www.1ys53p.top/1722331487048.webp",
        text:"弱小和无知不是生存的障碍，傲慢才是",
        time: "2024-07-30",
      },
      paperText34: {
        title: "那边",
        ptitle: "Dawn ❤️",
        imageUrl: "https://www.1ys53p.top/1722676865179.jpg",
        text:"海的那边是什么？这其实一点都不重要，因为我逐渐意识到，海其实没有边的",
        time: "2024-08-03",
      },
      paperText35: {
        title: "千年女優",
        ptitle: "今敏 🎥",
        imageUrl: "https://www.1ys53p.top/1722775441132.png",
        text:"会怎么样呢？不过，也许不管怎么样都没关系，因为我喜欢追寻着那个人的自己",
        time: "2024-08-04",
      },
      paperText36: {
        title: "化了",
        ptitle: "Dawn 🍦",
        imageUrl: "https://www.1ys53p.top/1723191717384.jpg",
        text:"我想和摊在沥青上的冰淇淋一样，感受温暖的时间慢慢融化冰冷的心",
        time: "2024-08-09",
      },
      paperText37: {
        title: "问题",
        ptitle: "闻香识女人 🌷",
        imageUrl: "https://www.1ys53p.top/1723787189849.jpg",
        text:"问题不是哪条路是对的，问题是：你敢不敢走？人即使身残，也应保持灵魂的完整，因为灵魂没有假肢",
        time: "2024-08-16",
      },
      paperText38: {
        title: "侦探",
        ptitle: "希望庄-宫部美雪 🕵️‍♀",
        imageUrl: "https://www.1ys53p.top/1724741913368.jpg",
        text:"我应该要怀疑的，因为我是侦探。实在太窝囊。更窝囊的是，我仍忍不住要祈祷——祈祷这戒指不是「动机」，而是「结果」",
        time: "2024-08-27",
      },
      paperText39: {
        title: "男孩",
        ptitle: "男孩和英雄-宫崎骏 👦",
        imageUrl: "https://www.1ys53p.top/1725692404286.jpg",
        text:"是啊，究竟该活出怎样的一生呢？",
        time: "2024-09-07",
      },
      paperText40: {
        title: "码头的薯条",
        ptitle: "外伶仃岛 🙆‍♀️🙆‍♂️",
        imageUrl: "https://www.1ys53p.top/1727143765556.jpg",
        text:"人生不需要意义，而意义需要人生",
        time: "2024-09-21",
      },
      paperText41: {
        title: "那本书",
        ptitle: "Dawn ❤️",
        imageUrl: "https://www.1ys53p.top/1727429023581.jpg",
        text:"一本书你重新读一遍，可能有新的感悟，甚至是全新的体验，但不会有新的结局",
        time: "2024-09-27",
      },
      paperText42: {
        title: "草民",
        ptitle: "蔡崇达 📚️",
        imageUrl: "https://www.1ys53p.top/1728610853495.jpg",
        text:"难道心生些对人生格外的期待，就要被庸常的生活嘲笑侮辱吗？",
        time: "2024-10-11",
      },
      paperText43: {
        title: "裂痕",
        ptitle: "蔡崇达 📚️",
        imageUrl: "https://www.1ys53p.top/1729755659199.jpg",
        text:"自意识到过去即崩解，我便难过地看着参与并构成自己人生的所有人和事，难过地数着时间在他们身上撕开的细密的裂痕：裂痕在脸上，我们称之为皱纹；裂痕在身体里，我们称之为疾病；裂痕在灵魂里，我们称之为遗忘..难过地想，到底能为此做点什么呢？",
        time: "2024-10-24",
      },
      paperText44: {
        title: "快乐",
        ptitle: "一一 ",
        imageUrl: "https://www.1ys53p.top/1729845354600.jpg",
        text:"快乐是一个过程，从来都不是结果",
        time: "2024-10-25",
      },
      paperText45: {
        title: "结局",
        ptitle: "辩方",
        imageUrl: "https://www.1ys53p.top/1732009587855.jpg",
        text:"这结局不该停在那，因为雪不该下在沙地上",
        time: "2024-11-19",
      },
      paperText46: {
        title: "童年",
        ptitle: "白鹤林-《孤独》",
        imageUrl: "https://www.1ys53p.top/1733037460716.jpg",
        text:"从童年起，我便独自一人，照顾着历代的星辰",
        time: "2024-11-19",
      },
      paperText47: {
        title: "路",
        ptitle: "《球状闪电》---刘慈欣",
        imageUrl: "https://www.1ys53p.top/1733192199300.jpg",
        text:"金黄色的树林里分出两条路，可惜我们不能同时去涉足，但我们却选择了，人迹罕至的那一条，这从此决定了我们的一生。",
        time: "2024-12-03",
      },
    };
  },
  components: {
    bigDisplay,
    cardVue,
    cardVueEN
  },
  methods: {},
};
</script>

<style scoped>
@font-face {
  font-family: "阿里妈妈灵动体 VF Thin";
  src: url("https://www.1ys53p.top/1719369555793.woff2") format("woff2");
  font-display: swap;
}
</style>